import React from "react";
import {
  Box,
  Typography,
  Link as MuiLink,
  Grid,
  useTheme,
  Container,
} from "@mui/material";
import { Link } from "react-router-dom";
import gglLogo from "../../assets/images/footer/ggl.jpg";
import begambleawareLogo from "../../assets/images/footer/begambleaware.png";
import checkDeinSpielLogo from "../../assets/images/footer/check-dein-spiel.png";
import bzgaLogo from "../../assets/images/footer/bzga.png";
import buweiLogo from "../../assets/images/footer/buwei.svg";
import eighteenPlusLogo from "../../assets/images/footer/18plus.png";

function Footer() {
  const theme = useTheme();

  const linkStyles = {
    color: "inherit",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
      color: "inherit",
    },
    "&:visited": {
      color: "inherit",
    },
    "&:active": {
      color: "inherit",
    },
  };

  return (
    <Box
      sx={{
        backgroundColor: "primary.main",
        color: "white",
        padding: theme.spacing(2.5),
        textAlign: "center",
        marginTop: theme.spacing(2.5),
      }}
    >
      <Container>
        {/* Grid für strukturierte Links */}
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <MuiLink component={Link} to="/impressum" sx={linkStyles}>
              Impressum
            </MuiLink>
          </Grid>
          <Grid item>
            <MuiLink component={Link} to="/datenschutz" sx={linkStyles}>
              Datenschutz
            </MuiLink>
          </Grid>
          <Grid item>
            <MuiLink component={Link} to="/agb" sx={linkStyles}>
              AGB
            </MuiLink>
          </Grid>
          <Grid item>
            <MuiLink component={Link} to="/spielerschutz" sx={linkStyles}>
              Spielerschutz
            </MuiLink>
          </Grid>
          <Grid item>
            <MuiLink component={Link} to="/kontakt" sx={linkStyles}>
              Kontakt
            </MuiLink>
          </Grid>
        </Grid>

        {/* Copyright Hinweis */}
        <Typography variant="body1" sx={{ marginTop: theme.spacing(2) }}>
          © 2025 EinfachNurZocken. Alle Rechte vorbehalten.
        </Typography>

        <Typography
          variant="body2"
          sx={{ marginTop: theme.spacing(2), color: "white" }}
        >
          18+ Glücksspiel kann süchtig machen - Hilfe findest du auf buwei.de,
          www.check-dein-Spiel.de, www.gamblingtherapy.org.lu, www.sos-jeu.ch,
          www.sos-spielsucht.ch and www.gamblingtherapy.org. Alle Anbieter auf
          dieser Webseite sind lizenziert. Wenn du diese Webseite von
          Deutschland aus besuchst, werden dir nur Anbieter angezeigt, die von
          der gemeinsamen Glücksspielbehörde der Länder lizenziert und reguliert
          sind.
        </Typography>

        {/* Glücksspielbehörde und Verantwortungsbewusstes Spielen Logos */}
        <Grid container spacing={2} justifyContent="center" sx={{ mt: 3 }}>
          {[
            {
              src: gglLogo,
              alt: "Gemeinsame Glücksspielbehörde der Länder",
              link: "https://www.ggl.gluecksspiel-behoerde.de/",
            },
            {
              src: begambleawareLogo,
              alt: "BeGambleAware",
              link: "https://www.begambleaware.org/",
            },
            {
              src: checkDeinSpielLogo,
              alt: "Check Dein Spiel",
              link: "https://www.check-dein-spiel.de/",
            },
            { src: bzgaLogo, alt: "BZgA", link: "https://www.bzga.de/" },
            { src: buweiLogo, alt: "Buwei", link: "https://www.buwei.de/" },
            {
              src: eighteenPlusLogo,
              alt: "18+ Glücksspiel kann süchtig machen",
              link: "",
            },
          ].map((logo, index) => (
            <Grid item key={index}>
              {logo.link ? (
                <MuiLink
                  href={logo.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={logo.src}
                    alt={logo.alt}
                    style={{
                      width: "100%",
                      maxHeight: 50,
                      objectFit: "contain",
                    }}
                  />
                </MuiLink>
              ) : (
                <img
                  src={logo.src}
                  alt={logo.alt}
                  style={{ width: "100%", maxHeight: 50, objectFit: "contain" }}
                />
              )}
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default Footer;
