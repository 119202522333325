import React from "react";
import { Typography, Box, Button } from "@mui/material";
import { onlineCasinoProvidersData as providersData } from "../../services/providersData";
import MainLayout from "../../components/layout/MainLayout";
import PageHeader from "../../components/casinos/providers/PageHeader";
import IntroductionSection from "../../components/casinos/providers/IntroductionSection";
import OverviewSection from "../../components/casinos/providers/OverviewSection";
import BonusSection from "../../components/casinos/providers/BonusSection";
import GamesSection from "../../components/casinos/providers/GamesSection";
import PaymentMethodsSection from "../../components/casinos/providers/PaymentMethodsSection";
import CustomerSupportSection from "../../components/casinos/providers/CustomerSupportSection";
import LoyaltyProgramSection from "../../components/casinos/providers/LoyaltyProgramSection";
import ConclusionSection from "../../components/casinos/providers/ConclusionSection";
import FAQSection from "../../components/casinos/providers/FAQSection";
import CustomList from "../../components/common/CustomList";

const SwiftSpieleReview = () => {
  const provider = providersData["swiftSpiele"];

  if (!provider) {
    return <div>Anbieter nicht gefunden</div>;
  }

  const summary = (
    <>
      <Typography variant="body1" gutterBottom>
        Swift Spiele ist die perfekte Wahl für alle, die Wert auf eine schnelle
        und reibungslose Casino-Erfahrung legen. Mit einer blitzschnellen
        Plattform, attraktiven Bonusangeboten und einer riesigen Auswahl an
        hochwertigen Slots bietet Swift Spiele ein erstklassiges Spielerlebnis.
      </Typography>
    </>
  );

  const bonusContent = (
    <>
      <Typography variant="h3" component="h3" gutterBottom>
        100% Einzahlungsbonus bis 100€ + 50 Freispiele
      </Typography>

      <CustomList
        listItems={[
          "50 Freispiele für ausgewählte Slots",
          "100€ Einzahlungsbonus (100%)",
          "Mindestumsatz: 30x Bonusbetrag",
          "Mindesteinzahlung: 10€",
        ]}
      />

      <Box sx={{ mt: 4, textAlign: "center" }}>
        <Button
          variant="contained"
          color="primary"
          href={provider.ctaLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          Jetzt bei Swift Spiele anmelden!
        </Button>
      </Box>
    </>
  );

  const gamesContent = (
    <>
      <Typography variant="body1" paragraph>
        Swift Spiele bietet eine breite Auswahl an Slots von Top-Entwicklern wie
        Play’n GO, Merkur, Gamomat & Greentube. Hier findest du alles von
        klassischen Frucht-Slots bis hin zu modernen Video-Slots mit
        beeindruckenden Grafiken und Features.
      </Typography>

      <Typography variant="body1" paragraph>
        Beliebte Slots bei Swift Spiele:
      </Typography>

      <CustomList
        listItems={[
          "Book of Dead",
          "Razor Shark",
          "Legacy of Dead",
          "Big Bass Bonanza",
        ]}
      />
    </>
  );

  const paymentMethodsContent = (
    <>
      <Typography variant="body1" paragraph>
        Swift Spiele ermöglicht schnelle und sichere Ein- und Auszahlungen. Zu
        den verfügbaren Zahlungsmethoden gehören unter anderem PayPal, Klarna,
        Trustly, Visa und Mastercard.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Blitz-Auszahlungen:</strong> Gewinne werden innerhalb von 24
        Stunden bearbeitet, sodass du dein Geld schnell erhältst.
      </Typography>
    </>
  );

  const customerSupportContent = (
    <>
      <Typography variant="body1" paragraph>
        Der Kundensupport von Swift Spiele ist rund um die Uhr per Live-Chat und
        E-Mail erreichbar. Das freundliche und kompetente Support-Team steht
        jederzeit zur Verfügung, um Fragen schnell und zuverlässig zu
        beantworten.
      </Typography>
    </>
  );

  const loyaltyProgramContent = (
    <>
      <Typography variant="body1" paragraph>
        Swift Spiele belohnt treue Spieler mit exklusiven VIP-Programmen, die
        zusätzliche Boni, Freispiele und personalisierte Angebote umfassen.
      </Typography>
    </>
  );

  const conclusionContent = (
    <>
      <Typography variant="body1" paragraph>
        Swift Spiele bietet eine blitzschnelle Plattform, eine riesige Auswahl
        an Spielen und attraktive Bonusangebote. Mit schnellen Auszahlungen und
        einem exzellenten Kundenservice überzeugt das Casino auf ganzer Linie.
      </Typography>
    </>
  );

  return (
    <MainLayout>
      <PageHeader
        title={provider.title}
        metaDescription={provider.metaDescription}
        logo={provider.logo}
        ctaLink={provider.ctaLink}
        providerName={provider.name}
      />

      <IntroductionSection
        summary={summary}
        pros={provider.pros}
        cons={provider.cons}
        ratings={provider.ratings}
      />

      <OverviewSection
        overview={provider.overview}
        ctaLink={provider.ctaLink}
      />

      <BonusSection
        bonusContent={bonusContent}
        ratings={provider.ratings}
        bonusDetails={provider.bonus}
      />

      <GamesSection gamesContent={gamesContent} ratings={provider.ratings} />

      <PaymentMethodsSection
        paymentMethodsContent={paymentMethodsContent}
        paymentMethods={provider.paymentMethods}
        ratings={provider.ratings}
      />

      <CustomerSupportSection
        customerSupportContent={customerSupportContent}
        ratings={provider.ratings}
      />

      <LoyaltyProgramSection
        loyaltyProgramContent={loyaltyProgramContent}
        ratings={provider.ratings}
      />

      <ConclusionSection
        conclusionContent={conclusionContent}
        ratings={provider.ratings}
        ctaLink={provider.ctaLink}
        buttonTitle="Jetzt bei Swift Spiele anmelden!"
      />

      <FAQSection faqs={provider.faqs} />
    </MainLayout>
  );
};

export default SwiftSpieleReview;
