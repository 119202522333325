import React from "react";
import {
  Box,
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OpenGraphMeta from "../../components/common/OpenGraphMeta";
import logo from "../../assets/images/logo.png";

// Daten für das Glossar/Wett-Guide
const glossaryData = [
  {
    letter: "A",
    terms: [
      {
        term: "Arbitrage",
        definition:
          "Eine Wettstrategie, bei der du durch das Platzieren von Wetten auf alle möglichen Ausgänge eines Ereignisses bei unterschiedlichen Anbietern garantiert einen Gewinn erzielst. Auch als 'Surebet' bekannt.",
      },
      {
        term: "Asien Handicap",
        definition:
          "Eine Wettart, bei der einer Mannschaft ein virtueller Vorsprung oder Rückstand (in Toren) gewährt wird. Dadurch entfällt die Möglichkeit eines Unentschiedens.",
      },
    ],
  },
  {
    letter: "B",
    terms: [
      {
        term: "Bank in einer Systemwette",
        definition:
          "Eine Auswahl, die unbedingt richtig sein muss, damit das System gewinnt. Banken erhöhen das Risiko, aber auch die potenzielle Rendite.",
      },
      {
        term: "Cash-Back",
        definition:
          "Ein Bonus, bei dem du einen Teil deines verlorenen Einsatzes zurückerhältst.",
      },
    ],
  },
  {
    letter: "C",
    terms: [
      {
        term: "Cashout",
        definition:
          "Die Möglichkeit, eine Wette vorzeitig auszuzahlen, bevor das Ereignis beendet ist.",
      },
      {
        term: "Doppelte Chance",
        definition:
          "Eine Wettart, bei der zwei von drei möglichen Ergebnissen (z. B. Sieg oder Unentschieden) abgedeckt werden.",
      },
      {
        term: "Draw no Bet",
        definition:
          "Eine Wettoption, bei der der Einsatz bei einem Unentschieden zurückerstattet wird.",
      },
    ],
  },
  {
    letter: "E",
    terms: [
      {
        term: "Einzahlungsbonus",
        definition:
          "Ein Bonus, den Wettanbieter neuen Spielern auf ihre erste Einzahlung gewähren.",
      },
      {
        term: "Ergebniswette",
        definition: "Eine Wette auf das genaue Endergebnis eines Spiels.",
      },
    ],
  },
  {
    letter: "F",
    terms: [
      {
        term: "Fantasy Wette",
        definition:
          "Eine Wettform, bei der virtuelle Mannschaften oder Spieler zusammengestellt werden und auf deren Leistungen gewettet wird.",
      },
      {
        term: "Faire Quote",
        definition:
          "Eine Quote, die das wahre Wahrscheinlichkeitsverhältnis eines Ereignisses widerspiegelt, ohne Buchmachergewinn.",
      },
      {
        term: "Free Bet",
        definition:
          "Eine Gratiswette, bei der der Einsatz vom Anbieter gestellt wird.",
      },
    ],
  },
  {
    letter: "G",
    terms: [
      {
        term: "Glücksspiel",
        definition:
          "Jede Form des Spiels, bei der der Ausgang primär vom Zufall abhängt.",
      },
      {
        term: "Glücksspielstaatsvertrag",
        definition:
          "Ein Gesetz, das die Regelungen für Glücksspiele in Deutschland festlegt.",
      },
    ],
  },
  {
    letter: "H",
    terms: [
      {
        term: "Handicap",
        definition:
          "Eine Wettform, bei der einer Mannschaft ein virtueller Vorsprung oder Rückstand gegeben wird, um die Quoten auszugleichen.",
      },
      {
        term: "Halbzeit/Endstand Wette",
        definition:
          "Eine Wette, die sowohl das Ergebnis zur Halbzeit als auch das Endergebnis vorhersagt.",
      },
      {
        term: "Head-to-Head",
        definition:
          "Eine Wette, bei der zwei Teilnehmer oder Teams direkt miteinander verglichen werden.",
      },
      {
        term: "Highroller",
        definition: "Spieler, die sehr hohe Einsätze bei Wetten platzieren.",
      },
    ],
  },
  {
    letter: "K",
    terms: [
      {
        term: "Kombiwette",
        definition:
          "Eine Wette, bei der mehrere Einzelwetten kombiniert werden. Alle Tipps müssen korrekt sein, damit die Wette gewinnt.",
      },
      {
        term: "Quote",
        definition:
          "Der Multiplikator, der angibt, wie viel du bei einem Gewinn ausgezahlt bekommst.",
      },
      {
        term: "Quotenschlüssel",
        definition:
          "Gibt an, wie viel Prozent der Einsätze der Buchmacher als Gewinn auszahlt. Ein hoher Schlüssel ist besser für die Spieler.",
      },
    ],
  },
  {
    letter: "L",
    terms: [
      {
        term: "Langzeitwette",
        definition:
          "Eine Wette auf ein Ereignis, das erst in der Zukunft entschieden wird (z. B. der Meister einer Liga).",
      },
      {
        term: "Livescore",
        definition: "Eine Echtzeitübersicht mit den aktuellen Spielständen.",
      },
      {
        term: "Liveticker",
        definition: "Textbasierte Echtzeitupdates zu einem Spiel.",
      },
      {
        term: "Livewette",
        definition:
          "Wetten, die während eines laufenden Spiels platziert werden.",
      },
      {
        term: "Livestream",
        definition:
          "Ein Videostream, mit dem Spiele live verfolgt werden können.",
      },
    ],
  },
  {
    letter: "M",
    terms: [
      {
        term: "Money Management",
        definition:
          "Strategien zur Verwaltung deines Wettbudgets, um Verluste zu minimieren und langfristig erfolgreich zu sein.",
      },
    ],
  },
  {
    letter: "S",
    terms: [
      {
        term: "Skrill",
        definition:
          "Ein beliebtes Online-Zahlungssystem, das von vielen Wettanbietern akzeptiert wird.",
      },
      {
        term: "Spezialwette",
        definition:
          "Wetten auf Ereignisse, die nicht direkt mit dem Ergebnis zusammenhängen (z. B. Anzahl der Ecken).",
      },
      {
        term: "Spielsucht",
        definition:
          "Eine schwerwiegende Abhängigkeit vom Glücksspiel. Verantwortungsbewusstes Spielen ist wichtig.",
      },
      {
        term: "Sportwetten Lizenz",
        definition:
          "Eine behördliche Genehmigung, die Buchmacher benötigen, um legal Wetten anzubieten.",
      },
      {
        term: "Spreadwette",
        definition:
          "Eine Wettart, bei der der Gewinn oder Verlust von der Höhe der Abweichung vom Einsatz abhängt.",
      },
    ],
  },
  {
    letter: "T",
    terms: [
      {
        term: "Torschützenwette",
        definition: "Eine Wette darauf, welcher Spieler ein Tor erzielt.",
      },
      {
        term: "Trixie",
        definition:
          "Eine Systemwette aus drei Tipps, die vier Wetten umfasst (drei Zweierkombinationen und eine Dreierkombination).",
      },
    ],
  },
  {
    letter: "V",
    terms: [
      {
        term: "Value-Bet",
        definition:
          "Eine Wette, bei der die Quote höher ist als die geschätzte Wahrscheinlichkeit des Ereignisses.",
      },
    ],
  },
  {
    letter: "W",
    terms: [
      {
        term: "Wettbüro",
        definition:
          "Ein physischer Ort, an dem Wetten abgeschlossen werden können.",
      },
      {
        term: "Wettguthaben",
        definition:
          "Der Betrag, den ein Spieler bei einem Buchmacher zur Verfügung hat.",
      },
      {
        term: "Wettsteuer",
        definition:
          "Eine gesetzliche Abgabe in Deutschland, bei der 5 % des Einsatzes oder des Gewinns abgeführt werden müssen.",
      },
    ],
  },
  {
    letter: "Y",
    terms: [
      {
        term: "Yankee",
        definition:
          "Eine Systemwette mit vier Tipps, bestehend aus elf Wetten (sechs Zweierkombinationen, vier Dreierkombinationen und einer Viererkombination).",
      },
    ],
  },
];

const WettGuide = () => {
  const pageTitle = "Wett-Guide: Das Wett-ABC für Einsteiger";
  const pageDescription =
    "Entdecke die wichtigsten Begriffe aus der Welt der Sportwetten in unserem Wett-Guide. Ideal für Einsteiger, die sich mit den Grundlagen vertraut machen möchten.";
  const pageUrl = "https://einfachnurzocken.com/sportwetten/wett-guide";
  const pageImage = logo;

  return (
    <Box sx={{ mt: 4, mb: 6 }}>
      <OpenGraphMeta
        title={pageTitle}
        description={pageDescription}
        url={pageUrl}
        image={pageImage}
      />

      {/* Überschrift */}
      <Typography
        variant="h1"
        component="h1"
        sx={{
          mb: 2,
          fontSize: {
            xs: "2rem",
            sm: "3rem",
            md: "4rem",
          },
          textAlign: "center",
        }}
      >
        {pageTitle}
      </Typography>

      {/* Beschreibung */}
      <Typography variant="body1" paragraph sx={{ mb: 4, textAlign: "center" }}>
        Unser Wett-Guide ist dein perfekter Einstieg in die Welt der
        Sportwetten. Hier findest du die wichtigsten Begriffe einfach und
        verständlich erklärt.
      </Typography>

      {/* Glossar */}
      {glossaryData.map((section) => (
        <Box key={section.letter} sx={{ mb: 4 }}>
          <Typography variant="h2" component="h2" sx={{ mb: 2 }}>
            {section.letter}
          </Typography>
          <Divider sx={{ mb: 2 }} />

          {section.terms.map((term, index) => (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-${section.letter}-${index}-content`}
                id={`panel-${section.letter}-${index}-header`}
              >
                <Typography variant="h3" component="h3">
                  {term.term}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1">{term.definition}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default WettGuide;
