import React from 'react';
import { Route } from 'react-router-dom';
import Bonus from '../pages/casinos/Bonus';
import News from '../pages/casinos/News';
import Guides from '../pages/casinos/Guides';
import Spiele from '../pages/casinos/Spiele';
import JackpotPiratenReview from '../pages/casinos/JackpotPiratenReview';
import BingBongReview from '../pages/casinos/BingBongReview';
import SwiftSpieleReview from '../pages/casinos/SwiftSpieleReview';
import LoewenplayReview from '../pages/casinos/LoewenplayReview';
import JokerstarReview from '../pages/casinos/JokerstarReview';
import LuckyLouisReview from '../pages/casinos/LuckylouisReview';
import PlayMillionReview from '../pages/casinos/PlayMillionReview';
import DrueckGlueckReview from '../pages/casinos/DrueckglueckReview';
import LunaReview from '../pages/casinos/LunaReview';

const CasinoRoutes = () => {
  return (
    <>
      <Route path="/online-spielotheken/bonus" element={<Bonus />} />
      <Route path="/online-spielotheken/news" element={<News />} />
      <Route path="/online-spielotheken/guides" element={<Guides />} />
      <Route path="/online-spielotheken/spiele" element={<Spiele />} />
      <Route path="/online-spielotheken/jackpotpiraten" element={<JackpotPiratenReview />} />
      <Route path="/online-spielotheken/bing-bong" element={<BingBongReview />} />
      <Route path="/online-spielotheken/swift-spiele" element={<SwiftSpieleReview />} />
      <Route path="/online-spielotheken/loewenplay" element={<LoewenplayReview />} />
      <Route path="/online-spielotheken/jokerstar" element={<JokerstarReview />} />
      <Route path="/online-spielotheken/luckylouis" element={<LuckyLouisReview />} />
      <Route path="/online-spielotheken/playmillion" element={<PlayMillionReview />} />
      <Route path="/online-spielotheken/drueckglueck" element={<DrueckGlueckReview />} />
      <Route path="/online-spielotheken/luna-slots" element={<LunaReview />} />
    </>
  );
};

export default CasinoRoutes;