import React from "react";
import { Typography, Box, Button } from "@mui/material";
import { onlineCasinoProvidersData as providersData } from "../../services/providersData";
import MainLayout from "../../components/layout/MainLayout";
import PageHeader from "../../components/casinos/providers/PageHeader";
import IntroductionSection from "../../components/casinos/providers/IntroductionSection";
import OverviewSection from "../../components/casinos/providers/OverviewSection";
import BonusSection from "../../components/casinos/providers/BonusSection";
import GamesSection from "../../components/casinos/providers/GamesSection";
import PaymentMethodsSection from "../../components/casinos/providers/PaymentMethodsSection";
import CustomerSupportSection from "../../components/casinos/providers/CustomerSupportSection";
import LoyaltyProgramSection from "../../components/casinos/providers/LoyaltyProgramSection";
import ConclusionSection from "../../components/casinos/providers/ConclusionSection";
import FAQSection from "../../components/casinos/providers/FAQSection";
import CustomList from "../../components/common/CustomList";

const JokerstarReview = () => {
  const provider = providersData["jokerstar"];

  if (!provider) {
    return <div>Anbieter nicht gefunden</div>;
  }

  const summary = (
    <>
      <Typography variant="body1" gutterBottom>
        Jokerstar setzt neue Maßstäbe in der Welt der Online-Casinos mit einer
        riesigen Spielauswahl, einem großzügigen Willkommensbonus und einem
        modernen Design. Hier erlebst du erstklassigen Spielspaß mit sicheren
        und schnellen Zahlungen.
      </Typography>
    </>
  );

  const bonusContent = (
    <>
      <Typography variant="h3" component="h3" gutterBottom>
        100% Einzahlungsbonus bis 100€ + 200 Freispiele
      </Typography>
      <CustomList
        listItems={[
          "200 Freispiele für ausgewählte Slots",
          "100€ Einzahlungsbonus (100%)",
          "Mindestumsatz: 35x Bonusbetrag",
          "Mindesteinzahlung: 10€",
        ]}
      />
      <Box sx={{ mt: 4, textAlign: "center" }}>
        <Button
          variant="contained"
          color="primary"
          href={provider.ctaLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          Jetzt bei Jokerstar anmelden!
        </Button>
      </Box>
    </>
  );

  const gamesContent = (
    <>
      <Typography variant="body1" paragraph>
        Jokerstar bietet eine beeindruckende Auswahl an Slots von führenden
        Entwicklern wie Play’n GO, Merkur, Push Gaming & Novomatic. Egal ob
        klassische oder moderne Video-Slots – hier findest du alles.
      </Typography>
      <Typography variant="body1" paragraph>
        Beliebte Slots bei Jokerstar:
      </Typography>
      <CustomList
        listItems={[
          "Book of Ra Deluxe",
          "Lord of the Ocean",
          "Faust",
          "Lucky Lady's Charm",
        ]}
      />
    </>
  );

  const paymentMethodsContent = (
    <>
      <Typography variant="body1" paragraph>
        Jokerstar ermöglicht schnelle und sichere Zahlungen mit Methoden wie
        PayPal, Klarna, Trustly, Visa und Mastercard.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Blitz-Auszahlungen:</strong> Gewinne werden innerhalb von 24
        Stunden bearbeitet, sodass du dein Geld schnell erhältst.
      </Typography>
    </>
  );

  const customerSupportContent = (
    <>
      <Typography variant="body1" paragraph>
        Der Kundenservice von Jokerstar ist rund um die Uhr per Live-Chat und
        E-Mail erreichbar. Das Support-Team hilft schnell und kompetent bei
        allen Fragen.
      </Typography>
    </>
  );

  const loyaltyProgramContent = (
    <>
      <Typography variant="body1" paragraph>
        Treue Spieler profitieren bei Jokerstar von exklusiven VIP-Programmen
        mit speziellen Boni, Freispielen und personalisierten Angeboten.
      </Typography>
    </>
  );

  const conclusionContent = (
    <>
      <Typography variant="body1" paragraph>
        Jokerstar überzeugt mit einer riesigen Spielauswahl, großzügigen
        Bonusangeboten und einer modernen Plattform. Ein sicheres und faires
        Spielerlebnis ist hier garantiert.
      </Typography>
    </>
  );

  return (
    <MainLayout>
      <PageHeader
        title={provider.title}
        metaDescription={provider.metaDescription}
        logo={provider.logo}
        ctaLink={provider.ctaLink}
        providerName={provider.name}
      />

      <IntroductionSection
        summary={summary}
        pros={provider.pros}
        cons={provider.cons}
        ratings={provider.ratings}
      />
      <OverviewSection
        overview={provider.overview}
        ctaLink={provider.ctaLink}
      />
      <BonusSection
        bonusContent={bonusContent}
        ratings={provider.ratings}
        bonusDetails={provider.bonus}
      />
      <GamesSection gamesContent={gamesContent} ratings={provider.ratings} />
      <PaymentMethodsSection
        paymentMethodsContent={paymentMethodsContent}
        paymentMethods={provider.paymentMethods}
        ratings={provider.ratings}
      />
      <CustomerSupportSection
        customerSupportContent={customerSupportContent}
        ratings={provider.ratings}
      />
      <LoyaltyProgramSection
        loyaltyProgramContent={loyaltyProgramContent}
        ratings={provider.ratings}
      />
      <ConclusionSection
        conclusionContent={conclusionContent}
        ratings={provider.ratings}
        ctaLink={provider.ctaLink}
        buttonTitle="Jetzt bei Jokerstar anmelden!"
      />
      <FAQSection faqs={provider.faqs} />
    </MainLayout>
  );
};

export default JokerstarReview;
