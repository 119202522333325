import React from "react";
import { Typography, Box, Button } from "@mui/material";
import { onlineCasinoProvidersData as providersData } from "../../services/providersData";
import MainLayout from "../../components/layout/MainLayout";
import PageHeader from "../../components/casinos/providers/PageHeader";
import IntroductionSection from "../../components/casinos/providers/IntroductionSection";
import OverviewSection from "../../components/casinos/providers/OverviewSection";
import BonusSection from "../../components/casinos/providers/BonusSection";
import GamesSection from "../../components/casinos/providers/GamesSection";
import PaymentMethodsSection from "../../components/casinos/providers/PaymentMethodsSection";
import CustomerSupportSection from "../../components/casinos/providers/CustomerSupportSection";
import LoyaltyProgramSection from "../../components/casinos/providers/LoyaltyProgramSection";
import ConclusionSection from "../../components/casinos/providers/ConclusionSection";
import FAQSection from "../../components/casinos/providers/FAQSection";
import CustomList from "../../components/common/CustomList";

const DrueckGlueckReview = () => {
  const provider = providersData["drueckglueck"];

  if (!provider) {
    return <div>Anbieter nicht gefunden</div>;
  }

  const summary = (
    <>
      <Typography variant="body1" gutterBottom>
        DrückGlück ist eine etablierte Online-Spielothek mit einem großzügigen
        Willkommensbonus, einer umfangreichen Spielauswahl und schnellen
        Auszahlungen. Die Plattform bietet hohe Sicherheitsstandards und eine
        lizenzierte Spielumgebung.
      </Typography>
    </>
  );

  const bonusContent = (
    <>
      <Typography variant="h3" component="h3" gutterBottom>
        100% Einzahlungsbonus bis 100€ + 50 Free Spins
      </Typography>
      <CustomList
        listItems={[
          "100% Einzahlungsbonus bis 100€",
          "50 Freispiele für ausgewählte Slots",
          "Mindestumsatz: 30x Bonus, 60x Freispiele",
          "Mindesteinzahlung: 10€",
        ]}
      />
      <Box sx={{ mt: 4, textAlign: "center" }}>
        <Button
          variant="contained"
          color="primary"
          href={provider.ctaLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          Jetzt bei DrückGlück anmelden!
        </Button>
      </Box>
    </>
  );

  const gamesContent = (
    <>
      <Typography variant="body1" paragraph>
        DrückGlück bietet eine breite Auswahl an Slots, Jackpot-Spielen,
        Tischspielen und ein Live-Casino mit echten Dealern. Entwickelt von
        renommierten Softwareanbietern wie NetEnt, Microgaming und Play’n GO.
      </Typography>
    </>
  );

  const paymentMethodsContent = (
    <>
      <Typography variant="body1" paragraph>
        Das Casino bietet sichere und schnelle Zahlungsmethoden, darunter Visa,
        Mastercard, PayPal und Skrill.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Bearbeitungszeiten:</strong> Auszahlungen erfolgen innerhalb von
        24-48 Stunden.
      </Typography>
    </>
  );

  const customerSupportContent = (
    <>
      <Typography variant="body1" paragraph>
        Der 24/7 Kundensupport ist per Live-Chat, E-Mail und Telefon erreichbar
        und bietet kompetente Hilfe bei Fragen oder Problemen.
      </Typography>
    </>
  );

  const loyaltyProgramContent = (
    <>
      <Typography variant="body1" paragraph>
        Treue Spieler profitieren von einem VIP-Programm mit exklusiven
        Belohnungen, Cashback-Angeboten und Sonderaktionen.
      </Typography>
    </>
  );

  const conclusionContent = (
    <>
      <Typography variant="body1" paragraph>
        DrückGlück überzeugt mit einem starken Bonusangebot, einer riesigen
        Spielauswahl und hohen Sicherheitsstandards. Dank der schnellen
        Auszahlungen und des zuverlässigen Supports ist es eine erstklassige
        Wahl für Spieler.
      </Typography>
    </>
  );

  return (
    <MainLayout>
      <PageHeader
        title={provider.title}
        metaDescription={provider.metaDescription}
        logo={provider.logo}
        ctaLink={provider.ctaLink}
        providerName={provider.name}
      />

      <IntroductionSection
        summary={summary}
        pros={provider.pros}
        cons={provider.cons}
        ratings={provider.ratings}
      />
      <OverviewSection
        overview={provider.overview}
        ctaLink={provider.ctaLink}
      />
      <BonusSection
        bonusContent={bonusContent}
        ratings={provider.ratings}
        bonusDetails={provider.bonus}
      />
      <GamesSection gamesContent={gamesContent} ratings={provider.ratings} />
      <PaymentMethodsSection
        paymentMethodsContent={paymentMethodsContent}
        paymentMethods={provider.paymentMethods}
        ratings={provider.ratings}
      />
      <CustomerSupportSection
        customerSupportContent={customerSupportContent}
        ratings={provider.ratings}
      />
      <LoyaltyProgramSection
        loyaltyProgramContent={loyaltyProgramContent}
        ratings={provider.ratings}
      />
      <ConclusionSection
        conclusionContent={conclusionContent}
        ratings={provider.ratings}
        ctaLink={provider.ctaLink}
        buttonTitle="Jetzt bei DrückGlück anmelden!"
      />
      <FAQSection faqs={provider.faqs} />
    </MainLayout>
  );
};

export default DrueckGlueckReview;
