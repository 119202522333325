import React from "react";
import { Typography, Box, Button } from "@mui/material";
import { onlineCasinoProvidersData as providersData } from "../../services/providersData";
import MainLayout from "../../components/layout/MainLayout";
import PageHeader from "../../components/casinos/providers/PageHeader";
import IntroductionSection from "../../components/casinos/providers/IntroductionSection";
import OverviewSection from "../../components/casinos/providers/OverviewSection";
import BonusSection from "../../components/casinos/providers/BonusSection";
import GamesSection from "../../components/casinos/providers/GamesSection";
import PaymentMethodsSection from "../../components/casinos/providers/PaymentMethodsSection";
import CustomerSupportSection from "../../components/casinos/providers/CustomerSupportSection";
import LoyaltyProgramSection from "../../components/casinos/providers/LoyaltyProgramSection";
import ConclusionSection from "../../components/casinos/providers/ConclusionSection";
import FAQSection from "../../components/casinos/providers/FAQSection";
import CustomList from "../../components/common/CustomList";

const LuckyLouisReview = () => {
  const provider = providersData["luckylouis"];

  if (!provider) {
    return <div>Anbieter nicht gefunden</div>;
  }

  const summary = (
    <>
      <Typography variant="body1" gutterBottom>
        LuckyLouis ist ein aufstrebendes Online-Casino mit einem attraktiven
        Willkommensbonus von bis zu 100 Super Spins. Mit einer breiten
        Spielauswahl und hohen Sicherheitsstandards ist es eine großartige Wahl
        für Spieler, die auf Qualität und Fairness setzen.
      </Typography>
    </>
  );

  const bonusContent = (
    <>
      <Typography variant="h3" component="h3" gutterBottom>
        100% Einzahlungsbonus bis 100 Super Spins
      </Typography>
      <CustomList
        listItems={[
          "1€ Einzahlung = 1 Super Spin (maximal 100 Spins)",
          "Mindestumsatz: 60x Bonusbetrag",
          "Mindesteinzahlung: 10€",
          "Gültig für neue Spieler",
        ]}
      />
      <Box sx={{ mt: 4, textAlign: "center" }}>
        <Button
          variant="contained"
          color="primary"
          href={provider.ctaLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          Jetzt bei LuckyLouis anmelden!
        </Button>
      </Box>
    </>
  );

  const gamesContent = (
    <>
      <Typography variant="body1" paragraph>
        LuckyLouis bietet eine beeindruckende Auswahl an Slots und Tischspielen
        von renommierten Entwicklern wie NetEnt, Microgaming und Play’n GO. Das
        Live-Casino mit echten Dealern sorgt für ein authentisches
        Spielerlebnis.
      </Typography>
      <Typography variant="body1" paragraph>
        Beliebte Spiele bei LuckyLouis:
      </Typography>
      <CustomList
        listItems={[
          "Book of Dead",
          "Starburst",
          "Gonzo’s Quest",
          "Lightning Roulette",
        ]}
      />
    </>
  );

  const paymentMethodsContent = (
    <>
      <Typography variant="body1" paragraph>
        LuckyLouis ermöglicht sichere Ein- und Auszahlungen mit verschiedenen
        Zahlungsmethoden, darunter PayPal, Kreditkarten und Banküberweisungen.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Bearbeitungszeiten:</strong> Auszahlungen werden in der Regel
        innerhalb von 24-48 Stunden verarbeitet.
      </Typography>
    </>
  );

  const customerSupportContent = (
    <>
      <Typography variant="body1" paragraph>
        Der Kundenservice von LuckyLouis ist rund um die Uhr per Live-Chat und
        E-Mail erreichbar. Die schnellen Reaktionszeiten und kompetenten
        Antworten machen ihn zu einem der besten in der Branche.
      </Typography>
    </>
  );

  const loyaltyProgramContent = (
    <>
      <Typography variant="body1" paragraph>
        Treue Spieler profitieren bei LuckyLouis von exklusiven VIP-Programmen
        mit speziellen Boni, Freispielen und personalisierten Angeboten.
      </Typography>
    </>
  );

  const conclusionContent = (
    <>
      <Typography variant="body1" paragraph>
        LuckyLouis überzeugt mit einem attraktiven Bonus, einer umfangreichen
        Spielauswahl und einem erstklassigen Kundenservice. Die Plattform ist
        sicher, fair und für Spieler aller Erfahrungsstufen geeignet.
      </Typography>
    </>
  );

  return (
    <MainLayout>
      <PageHeader
        title={provider.title}
        metaDescription={provider.metaDescription}
        logo={provider.logo}
        ctaLink={provider.ctaLink}
        providerName={provider.name}
      />

      <IntroductionSection
        summary={summary}
        pros={provider.pros}
        cons={provider.cons}
        ratings={provider.ratings}
      />
      <OverviewSection
        overview={provider.overview}
        ctaLink={provider.ctaLink}
      />
      <BonusSection
        bonusContent={bonusContent}
        ratings={provider.ratings}
        bonusDetails={provider.bonus}
      />
      <GamesSection gamesContent={gamesContent} ratings={provider.ratings} />
      <PaymentMethodsSection
        paymentMethodsContent={paymentMethodsContent}
        paymentMethods={provider.paymentMethods}
        ratings={provider.ratings}
      />
      <CustomerSupportSection
        customerSupportContent={customerSupportContent}
        ratings={provider.ratings}
      />
      <LoyaltyProgramSection
        loyaltyProgramContent={loyaltyProgramContent}
        ratings={provider.ratings}
      />
      <ConclusionSection
        conclusionContent={conclusionContent}
        ratings={provider.ratings}
        ctaLink={provider.ctaLink}
        buttonTitle="Jetzt bei LuckyLouis anmelden!"
      />
      <FAQSection faqs={provider.faqs} />
    </MainLayout>
  );
};

export default LuckyLouisReview;
