import React from "react";
import { Typography, Box, Button } from "@mui/material";
import { onlineCasinoProvidersData as providersData } from "../../services/providersData";
import MainLayout from "../../components/layout/MainLayout";
import PageHeader from "../../components/casinos/providers/PageHeader";
import IntroductionSection from "../../components/casinos/providers/IntroductionSection";
import OverviewSection from "../../components/casinos/providers/OverviewSection";
import BonusSection from "../../components/casinos/providers/BonusSection";
import GamesSection from "../../components/casinos/providers/GamesSection";
import PaymentMethodsSection from "../../components/casinos/providers/PaymentMethodsSection";
import CustomerSupportSection from "../../components/casinos/providers/CustomerSupportSection";
import LoyaltyProgramSection from "../../components/casinos/providers/LoyaltyProgramSection";
import ConclusionSection from "../../components/casinos/providers/ConclusionSection";
import FAQSection from "../../components/casinos/providers/FAQSection";
import CustomList from "../../components/common/CustomList";

const PlayMillionReview = () => {
  const provider = providersData["playmillion"];

  if (!provider) {
    return <div>Anbieter nicht gefunden</div>;
  }

  const summary = (
    <>
      <Typography variant="body1" gutterBottom>
        PlayMillion ist eine renommierte Online-Spielothek mit einem attraktiven
        Willkommensbonus und einer großen Spielauswahl. Dank hoher
        Sicherheitsstandards und schneller Auszahlungen ist es eine erstklassige
        Wahl für Casino-Fans.
      </Typography>
    </>
  );

  const bonusContent = (
    <>
      <Typography variant="h3" component="h3" gutterBottom>
        100% Einzahlungsbonus bis 100€ + 25 Free Spins
      </Typography>
      <CustomList
        listItems={[
          "100% Einzahlungsbonus bis 100€",
          "25 Freispiele für ausgewählte Slots",
          "Mindestumsatz: 60x Bonusbetrag",
          "Mindesteinzahlung: 10€",
        ]}
      />
      <Box sx={{ mt: 4, textAlign: "center" }}>
        <Button
          variant="contained"
          color="primary"
          href={provider.ctaLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          Jetzt bei PlayMillion anmelden!
        </Button>
      </Box>
    </>
  );

  const gamesContent = (
    <>
      <Typography variant="body1" paragraph>
        PlayMillion bietet eine beeindruckende Auswahl an Slots, Tischspielen
        und Live-Casino-Spielen von Entwicklern wie NetEnt, Microgaming und
        Play’n GO.
      </Typography>
      <Typography variant="body1" paragraph>
        Beliebte Spiele bei PlayMillion:
      </Typography>
      <CustomList
        listItems={[
          "Book of Dead",
          "Starburst",
          "Gonzo’s Quest",
          "Lightning Roulette",
        ]}
      />
    </>
  );

  const paymentMethodsContent = (
    <>
      <Typography variant="body1" paragraph>
        PlayMillion ermöglicht sichere und schnelle Zahlungen mit Methoden wie
        PayPal, Kreditkarten und Banküberweisungen.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Bearbeitungszeiten:</strong> Auszahlungen werden in der Regel
        innerhalb von 24-48 Stunden bearbeitet.
      </Typography>
    </>
  );

  const customerSupportContent = (
    <>
      <Typography variant="body1" paragraph>
        Der Kundensupport von PlayMillion ist rund um die Uhr per Live-Chat,
        E-Mail und Telefon erreichbar und zeichnet sich durch schnelle und
        kompetente Hilfe aus.
      </Typography>
    </>
  );

  const loyaltyProgramContent = (
    <>
      <Typography variant="body1" paragraph>
        Treue Spieler profitieren bei PlayMillion von exklusiven
        VIP-Belohnungen, Cashback-Angeboten und regelmäßigen Sonderaktionen.
      </Typography>
    </>
  );

  const conclusionContent = (
    <>
      <Typography variant="body1" paragraph>
        PlayMillion überzeugt mit einem starken Willkommensbonus, einer riesigen
        Spielauswahl und hohen Sicherheitsstandards. Dank der schnellen
        Auszahlungen und des zuverlässigen Supports ist es eine erstklassige
        Wahl für Spieler.
      </Typography>
    </>
  );

  return (
    <MainLayout>
      <PageHeader
        title={provider.title}
        metaDescription={provider.metaDescription}
        logo={provider.logo}
        ctaLink={provider.ctaLink}
        providerName={provider.name}
      />

      <IntroductionSection
        summary={summary}
        pros={provider.pros}
        cons={provider.cons}
        ratings={provider.ratings}
      />
      <OverviewSection
        overview={provider.overview}
        ctaLink={provider.ctaLink}
      />
      <BonusSection
        bonusContent={bonusContent}
        ratings={provider.ratings}
        bonusDetails={provider.bonus}
      />
      <GamesSection gamesContent={gamesContent} ratings={provider.ratings} />
      <PaymentMethodsSection
        paymentMethodsContent={paymentMethodsContent}
        paymentMethods={provider.paymentMethods}
        ratings={provider.ratings}
      />
      <CustomerSupportSection
        customerSupportContent={customerSupportContent}
        ratings={provider.ratings}
      />
      <LoyaltyProgramSection
        loyaltyProgramContent={loyaltyProgramContent}
        ratings={provider.ratings}
      />
      <ConclusionSection
        conclusionContent={conclusionContent}
        ratings={provider.ratings}
        ctaLink={provider.ctaLink}
        buttonTitle="Jetzt bei PlayMillion anmelden!"
      />
      <FAQSection faqs={provider.faqs} />
    </MainLayout>
  );
};

export default PlayMillionReview;
